.header-font {
  font-family: 'Merriweather', serif;
  font-size: 32px;
  font-weight: 100;
}

.title-font {
  font-family: 'Merriweather', serif;
  color: #3D4850;
  font-size: 24px;
  font-weight: 100;
}

.inner-title-font {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #3D4850;
  font-size: 18px;
  font-weight: 400;
}

p, li, body, label, .regular-font {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #001E20;
  line-height: 21px;
}

.bold-font {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #3D4850;
  font-weight: 600;
}