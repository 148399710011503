.multi-row {
  display: flex;
  border-bottom: 1px solid #CECFD1;;
  flex: 1;
  padding: 0;
}

.multi-button-row {
  display: flex;
  flex: 1;
  padding: 0;
  align-items: flex-start;
}

.multi-button-row__text {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}

.multi-top>p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  padding: 0px 4px 0px 4px;
}

.multi-inputrow, .multi-inputrow-tight {
  /*align-items: center;*/
}

.multi-inputrow>div {
  padding: 8px 4px 8px 0px;
}

.multi-inputrow-tight>div {
  padding: 4px 4px 4px 0px;
}

.multi-radio-label {
  font-size: 12px;
  font-weight: 500;
}
