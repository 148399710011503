/**
 - Used Across all
 */

.overall-outer {
  flex: 1;
  background-color: #F3F5F7;
}

.results__title {
  font-size: 16px;
}

.results__title-container {
  min-width: 150px
}

.overall-inner {
  padding: 15px;
  border-left: 2px solid white;
}

.overall-inner:first-child {
  border: none;
}

.overall-inner__image {
  display: flex;
  align-items: center;
}

.overall-outer p {
  color: #3D4850;
}

.result-percent {
  font-size: 21px;
  font-weight: 400;
}

.align-right {
  text-align: right;
}

.center {
  text-align: center;
}

.results-plan__table td.results-plan__table-title {
  font-weight: bold;
  text-align: right;
}

/**
  - OverallTop
 */
.overall-result-details__container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 100%;
}

.overall-result-details__item {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

/**
 - OverAll Charges
 */
.results-charges__title {
  width: 150px;
}

.results-charges__section {
  border-right: 2px solid white;
  background-color: #F3F5F7;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

}
.results-charges__section:last-child {
  border: none;
}

.results-charges__iac-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.results-charges__iac-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 191px;
}

.results-charges__iac-options {
  display: flex;
  flex-direction: column;
}

.results-charges__iac-title {
  font-weight: bold;
  align-self: center;
}

.results-charges__iac-button {
  padding: 1px 10px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  color: #F3F5F7;
  background-color: #AEBFCB;
  font-weight: bold;
}
.results-charges__iac-text {
  text-align: center;
  color: #3D4850;
}
.results-charges__iac-percent {
  padding: 4px;
  background-color: transparent;
  border: none;
}

.results-charges-terms {
  display: flex;
  flex-direction: row;
}

.results-charges__amc-container {
  max-width: 200px;
}

.results-charges__amc-title {
  text-align: center;
  font-weight: bold;
}

.results-charges__amc-text {
  font-weight: bold;
}

.results-charges__amc, .results-charges__ewc {
  font-weight: normal;
}


.results-charges__iaf-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.results-charges__iaf-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/**
 - overallPlan
 */

.results-plan__container {
  border-top: 1px solid #B5C6D1;
  border-bottom: 1px solid #B5C6D1;
  padding: 15px;
}

.results-plan__container.excluded {
  color: #C4C7CA;
  transition: color 0.2s;
}

.results-plan__section {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.results-plan__overlay {
  top: 0;
  right: 0;
}

.results-plan__overlay-button {
  background-color: white;
}

.results-plan__overlay-excluded-container {
  position: absolute;
  top: 0;
  right: 0;
}

.results-plan__overlay-image {
  margin-top: 1px;
  width: 200px;
}

.results-charges__iac-terms {
  text-align: center;
}

.overall-results__container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.overall-results__row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.overall-results__col-left {
  width: 150px;
  min-width: 150px;
  text-align: right;
  margin: 2px;
  display: flex;
  justify-content: flex-end;
}

.overall-results__col-right {
  flex: 1;
  margin: 2px;
  display: flex;
}

.overall-results__bottom {
  align-items: flex-end;
}

.results-charges__iac-options-title {
  min-width: 155px;
}

