
.outer {
  min-width: 1024px;
  margin: auto;
}

.inner {
  max-width: 1280px;
  min-width: 1024px;
  margin: auto;
  padding: 10px;
}



.h100 {height: 100%;}

.w100 {width: 100%;}
.w50 {width: 50%;}
.w30 {width: 30%;}
.w25 {width: 25%;}
.w10 {width: 10%;}

.minw120 {
  min-width: 120px;
}

.f10 {flex: 10}
.f80 {flex: 80}

.squash-help {margin-bottom: -50px;}