.summary-table {
  background-color: #FFF;
  border-spacing: 2px;
  border-collapse: separate;
  width: 100%;
}

.summary-table th {
  background-color: #DBE3E8;
  padding: 16px 12px;
}

.summary-table td {
  background-color: #F3F5F7;
  padding: 16px 12px;
}