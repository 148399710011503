.dialog-outer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  z-index: 100;
}

.dialog-inner {
  width: 400px;
  background-color: #FFF;
  margin: auto;
}

.dialog-title {
  background-color: #0F68A2;
  color: #FFF;
  padding: 8px;
}

.dialog-text {
  padding: 8px;
}

.dialog-buttons {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}
