.input-outer, .flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.input-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-left: 20px;
}


.top-align {
  justify-content: flex-start;
  margin-top: 4px;
}

.input-label-text {
  color: #3D4850;
  font-weight: 500;
  padding-top: 5px;
}

.input-title-text {
  color: #3D4850;
  font-weight: 500;
  font-size: 18px;
  align-self: flex-start;
  text-align: left;
  padding-top: 5px;
}

.input-input {
  flex: 2;
  padding: 10px 30px 10px 20px;
  border-right: 2px solid  #EC6F16;
}

.input-radio {
  display: flex;
  align-items: flex-start;
}

.input-radios-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-radios-vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}

.input-help {
  flex: 2;
  padding: 10px 20px;
  color: #3D4850;
}

.input-text {
  padding: 6px 8px 6px 8px;
  height: 40px;
  border-radius: 0px;
  color: #3D4850;
  border: 1px solid #AEBFCB;
}

.input-textarea {
  padding: 6px 8px;
  border-radius: 0px;
  color: #3D4850;
  border: 1px solid #AEBFCB;
}

.rightpad50 {
  padding-right: 50px; 
}

.subtext {
  font-size: 11px;
  color: #999;
}

select {
  border: 1px solid #ccc;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(selectarrow.png) 100% no-repeat #FFF;
}

select::-ms-expand {
    display: none; /* remove default arrow in IE 10 and 11 */
}


.input-group-text {
  border-radius: 0px;
}

.less-padded {
  padding: 0.375rem 0.2rem;
}

.input-warning {
  color: red;
}

.invalid-error {
  color: #F00
}

.invalid-error-label {
  padding-bottom: 21px;
}

.align-label-top {
  justify-content: flex-start;
  margin-top: 15px;
}
