.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 2px 0 0;
}

.radio-item input[type='radio'] {
  opacity: 0;
  position: absolute;
}

.radio-item label {
  color: #333;
  font-weight: normal;
  cursor: pointer;
  font-family: Roboto, Arial, Helvetica, sans-serif
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #95ABBB;
  background-color: #ffffff;
}

.radio-item input[type=radio]:checked+label:after {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 10px;
  left: 11px;
  content: " ";
  display: block;
  background: #95ABBB;
}

input[type="radio"]:focus + label:before {
  box-shadow: 0px 0px 6px rgba(63, 165, 190, 1);
}