body {
  background-color: #3E4850; 
  min-height: 100%;
}

.flex-center-vertical {
  display: flex; 
  justify-content: flex-start; 
  align-items: center; 
}

.flex-center-center {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; 
  align-items: center; 
}

.right-align {
  text-align: right;
}

.hidden {display: none;}

a {
  color: #1067A0;
  text-decoration: underline;
}
a:hover {
  color: rgb(9, 84, 134);
  text-decoration: underline;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.main-outer {
  background-color: #FFF;
}

.main-center {
  background-color: #F3F5F7;
  border: 1px solid #AEBFCB;
  padding: 20px 0px;
}

.main-center-no-border {
  background-color: #F3F5F7;
  padding: 20px 0px;
}

.normal-text {
  color: #3D4850 !important; 
}

.disabled-text {
  color: #999 !important;
}