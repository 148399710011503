.footer-outer {
  background-color: å#3D4850;
  height: 120px;
}

.footer-inner {
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 1280px;
  margin: auto;
}

.footer-text-bold {
  color: white;
  font-weight: 500;
  margin-bottom: 4px;
}

.footer-text {
  color: white;
  font-weight: 300;
  margin-bottom: 4px;
}
