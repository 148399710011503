

.intro-outer {
  background-color: #fff;
}

.intro-main {
  padding: 40px 15px;
}

.red {
  color: red;
}

.intro-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.intro-center {
  background-color: #FFF;
  padding: 20px 0 0 0;
  min-height: 400px;
}

.btn-intro-select {
  margin-left: -1px;
  height: 48px;
}

.inner-subtext {
  background-color: #FFF;
  padding: 10px 20px;
  display: none;
  font-weight: 300;
}

.inner-subtext__important {
  font-weight: 600;
}

.inner-subtext p, .inner-subtext li {
  color: #3D4850;
}


#btnIntroStart {
  width: 120px;
}
