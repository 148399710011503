.main-outer {
  background-color: #FFF;
}

.main-center {
  background-color: #F3F5F7;
  border: 1px solid #AEBFCB;
  padding: 20px 0px;
}

.retirement-main {

}