.menu-outer {
  background-color: #DBE3E8;
  height: 68px;
}

.menu-outer-padding {
  background-color: #FFF;
  height: 20px;
}

.menu-inner {
  display: flex;
  border: 0px solid #FFF;
  height: 48px;
  padding: 10px 10px 0 10px;
  max-width: 1280px;
  min-width: 1024px;
  margin: auto;
  padding: 0px;
}

.left-border {
  border-left: 2px solid #FFF;
}

.menu-item {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #DBE3E8;
}

.menu-item a {
  text-decoration: none;
}


.menu-item.active {
  background-color: #F3F5F7;
  background-size: contain;
}

.menu-tick {
  background-image: url(menutick.png);
  content: url(menutick.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 32px;
}

.menu-arrow {
  background-image: url(arrow00.png);
  content: url(arrow00.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 48px;
  width: 23px;
}

.menu-arrow.active {
  background-image: url(arrow10.png);
  content: url(arrow10.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.menu-arrow.inverted {
  background-image: url(arrow01.png);
  content: url(arrow01.png);
  background-repeat: no-repeat;
  background-size: contain;
}



#step2 {
  display: none;
}
