.btn {
  border-radius: 0;
}

.btn-primary, .btn-primary:visited {
  background-color: #1378BB;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:active:focus {
  background-color: rgb(31, 141, 214);
}

.btn-primary:disabled {
  background-color: #778191;
  border-color: #666;
}

.btn-outline-primary, .btn-outline-primary:visited {
  border-color: #1378BB;
  color: #1378BB;
}

.btn-outline-primary:active {
  background-color: #1378BB;
  color: #FFF;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.bottom-buttons {
  width: 100%;
  background-color: #FFF;
  height: 60px;
}

.bottom-buttons-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: 100%;
  max-width: 1260px;
  min-width: 1024px;
  margin: auto;
}

.bottom-buttons-detached {
  position: fixed;
  bottom: 0;
  left: 0;
}