.loading-overlay-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  color: #FFF;
  font-size: 2em;
}

.loader {
  border: 4px solid #888; /* Light grey */
  border-top: 4px solid #FFF; /* Blue */
  border-radius: 50%;
  width: 28px;
  height: 28px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}