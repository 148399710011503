.header-outer {
  background-color: #0A1428;
  height: 145px;
}


.header-logo {
  height: 90px;
  margin-left: 15px;
}

.header-logo-old {
  height: 72px;
  border-right: 2px solid #D8D8D8; 
  padding-right: 10px;
  margin-left: 15px;
}

.header-title{
  color:white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.banner {
  width: 100%;
  height: 243px; 
  background-color: #fff;
}

.bannerimage {
  width: 100%; height: 243px; object-fit: cover;
}