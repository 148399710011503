.popup-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
}

.popup-inner {
  width: 800px;
  height: 500px;
  background-color: #FFF;
}

.popup-title {
  background-color: #0F68A2;
  color: #FFF;
  padding: 8px;
}

.popup-text {
  padding: 8px;
}

.popup-buttons {
  display: flex;
  justify-content: center;
  padding: 8px;
}
